import React from "react"
import taiChinhImage from "../images/taichinh.jpg"
import batdongsanImage from "../images/batdongsan.jpg"
import giaoducImage from "../images/giaoduc.jpg"
import spaImage from "../images/spa.jpg"
import firebase from "gatsby-plugin-firebase"

import {
  Button,
  Container,
  Text,
  Div,
  Icon,
  Input,
  Anchor,
  Image,
  Row,
  Col,
} from "atomize"
export const nganhs = [
  {
    name: "Tài Chính",
    image: taiChinhImage,
    code: "tai-chinh",
  },
  {
    name: "Giáo Dục",
    image: giaoducImage,
    code: "giao-duc",
  },
  {
    name: "Bất Động Sản",
    image: batdongsanImage,
    code: "bat-dong-san",
  },
  {
    name: "Làm Đẹp & Spa",
    image: spaImage,
    code: "spa",
  },
]
export const CardCategory = ({ nganh }) => {
  return (
    <Div
      d={{ lg: "block" }}
      border="1px solid"
      borderColor="gray200"
      w={{ xs: "100%", md: "17rem" }}
      rounded="xl"
      bg="white"
      shadow="4"
      overflow="hidden"
    >
      <Div
        bgImg={nganh.image}
        bgSize="cover"
        bgPos="center"
        bgRepeat="no-repeat"
        p={{ b: "84%" }}
      />
      <Div
        p={{ l: "1rem", r: "1rem", t: "1rem" }}
        d="flex"
        align="center"
        justify="center"
      >
        <Div d="flex" align="center">
          <Text
            textAlign={"center"}
            textTransform={"uppercase"}
            textWeight="700"
            textSize={"20px"}
          >
            {nganh.name}
          </Text>
        </Div>
      </Div>
      <Div p="1rem">
        <a href={`../${nganh.code}`}>
          <Button
            w={"100%"}
            rounded="circle"
            h="3rem"
            bg="info200"
            hoverBg="info300"
            textColor="info700"
            onClick={() => {
              firebase.analytics().logEvent(`tap_on_button_${nganh.code}`)
              window.location.href = `../${nganh.code}`
            }}
          >
            Tìm Hiểu
          </Button>
        </a>
      </Div>
    </Div>
  )
}
