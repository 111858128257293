import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { setState } from "react"
import { Div, Image, Container, Button, Anchor, Dropdown, Icon } from "atomize"
import logo from "../../images/logo.svg"
import producthunt from "../../images/logo-producthunt.svg"
import firebase from "gatsby-plugin-firebase"
import { nganhs } from "../CardCategory"

const documentSubMenuList = (
  <Div p={{ x: "1rem", y: "0.5rem" }}>
    <Anchor href={`../help#steps`} d="block" p={{ y: "0.25rem" }}>
      4 bước sử dụng
    </Anchor>
    <Anchor
      onClick={() => {
        firebase.analytics().logEvent("open_data_feature_menu")
      }}
      href={`../help#video`}
      d="block"
      p={{ y: "0.25rem" }}
    >
      Video hướng dẫn
    </Anchor>
    <Anchor
      onClick={() => {
        firebase.analytics().logEvent("open_report_feature_menu")
      }}
      href={`../help#script`}
      d="block"
      p={{ y: "0.25rem" }}
    >
      Tạo kịch bản
    </Anchor>
  </Div>
)

const featuresSubMenuList = (
  <Div p={{ x: "1rem", y: "0.5rem" }}>
    <Anchor
      onClick={() => {
        firebase.analytics().logEvent("open_call_feature_menu")
      }}
      href={`../features#call`}
      d="block"
      p={{ y: "0.25rem" }}
    >
      Gọi tự động hàng nghìn cuộc gọi
    </Anchor>
    <Anchor
      onClick={() => {
        firebase.analytics().logEvent("open_data_feature_menu")
      }}
      href={`../features#data`}
      d="block"
      p={{ y: "0.25rem" }}
    >
      Làm giàu thông tin khách hàng
    </Anchor>
    <Anchor
      onClick={() => {
        firebase.analytics().logEvent("open_report_feature_menu")
      }}
      href={`../features#report`}
      d="block"
      p={{ y: "0.25rem" }}
    >
      Báo cáo, thống kê
    </Anchor>
  </Div>
)

const useCasesSubMenuList = (
  <Div p={{ x: "1rem", y: "0.5rem" }}>
    {nganhs.map((r) => (
      <Anchor href={`../${r.code}`} d="block" p={{ y: "0.25rem" }}>
        {r.name}
      </Anchor>
    ))}
  </Div>
)

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showMobileHeaderMenu: false,
      showProductHunt: true,
      showDropDownFeatures: false,
      showDropDownUseCase: false,
      showDropDownHelp: false,
    }
  }

  toggleHeaderMenu = (value) => {
    this.setState({ showMobileHeaderMenu: value })

    setTimeout(() => {
      window.scrollTo(0, window.scrollY + 1)
    }, 400)
  }

  render() {
    const {
      showMobileHeaderMenu,
      showProductHunt,
      layout,
      showDropDownFeatures,
      showDropDownUseCase,
      showDropDownHelp,
    } = this.state

    return (
      <>
        <ProductHuntBanner
          show={showProductHunt}
          close={() => this.setState({ showProductHunt: false })}
        />
        <Div
          tag="header"
          pos="fixed"
          top="0"
          transition
          left="0"
          right="0"
          zIndex="100"
          p={{ y: { xs: "1.5rem", md: "1rem" } }}
        >
          <Div
            pos="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg="white"
            opacity="1"
            zIndex="-1"
          ></Div>
          <Container d="flex" align="center" justify="space-between">
            <Div cursor="pointer">
              <a href={"../"}>
                <Image src={logo} alt="ucall logo" h="32px" w="auto" />
              </a>
            </Div>
            <Div
              d={{ xs: "flex", md: "none" }}
              flexDir="column"
              onClick={() => this.toggleHeaderMenu(!showMobileHeaderMenu)}
            >
              <Div
                h="2px"
                w="1rem"
                bg="black"
                rounded="lg"
                style={{
                  transform: `translateY(${
                    showMobileHeaderMenu ? "1" : "-2"
                  }px)rotate(${showMobileHeaderMenu ? "135" : "0"}deg)`,
                }}
                transition
              ></Div>
              <Div
                h="2px"
                w="1rem"
                bg="black"
                rounded="lg"
                style={{
                  transform: `translateY(${
                    showMobileHeaderMenu ? "-1" : "2"
                  }px)rotate(${showMobileHeaderMenu ? "45" : "0"}deg)`,
                }}
                transition
              ></Div>
            </Div>

            {/* Links for Desktop */}
            <Div
              d="flex"
              onClick={() => this.toggleHeaderMenu(false)}
              bg={{ xs: "white", md: "transparent" }}
              align={{ xs: "strech", md: "center" }}
              flexDir={{ xs: "column", md: "row" }}
              pos={{ xs: "absolute", md: "static" }}
              p={{
                t: { xs: "6rem", md: "0" },
                b: { xs: "2rem", md: "0" },
                x: { xs: "1.5rem", md: "0" },
              }}
              top="0"
              left="0"
              right="0"
              zIndex={{ xs: "-1", md: "0" }}
              shadow={{ xs: "4", md: "0" }}
              opacity={{
                xs: showMobileHeaderMenu ? "1" : "0",
                md: "1",
              }}
              transform={{
                xs: `translateY(${showMobileHeaderMenu ? "0" : "-100%"})`,
                md: "none",
              }}
              transition
            >
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  this.setState({
                    showDropDownFeatures: !showDropDownFeatures,
                  })
                  firebase.analytics().logEvent("open_features_menu")
                }}
              >
                <Dropdown
                  focusBorderColor="transparent"
                  borderColor="transparent"
                  textWeight="500"
                  textColor="medium"
                  hoverTextColor="black"
                  menu={featuresSubMenuList}
                  isOpen={showDropDownFeatures}
                >
                  Tính Năng
                </Dropdown>
              </div>
              <div
                onMouseEnter={() => {
                  this.setState({
                    showDropDownUseCase: !showDropDownUseCase,
                  })
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  this.setState({
                    showDropDownUseCase: !showDropDownUseCase,
                  })
                  firebase.analytics().logEvent("open_use_case_menu")
                }}
              >
                <Dropdown
                  focusBorderColor="transparent"
                  borderColor="transparent"
                  textWeight="500"
                  textColor="medium"
                  hoverTextColor="black"
                  isOpen={showDropDownUseCase}
                  menu={useCasesSubMenuList}
                >
                  Use Case
                </Dropdown>
              </div>

              <div style={{ paddingLeft: "0.8rem", paddingBottom: "0.4rem" }}>
                <Anchor
                  href="../pricing"
                  m={{ r: "2.5rem", b: { xs: "4rem", md: "0" } }}
                  textWeight="500"
                  textColor="medium"
                  hoverTextColor="black"
                  transition
                  onClick={() => {
                    firebase.analytics().logEvent("view_pricing")
                  }}
                >
                  Bảng giá
                </Anchor>
              </div>
              <div style={{ paddingLeft: "0.8rem", paddingBottom: "0.4rem" }}>
                <Anchor
                  href="https://blog.ucall.asia"
                  target="_blank"
                  m={{ r: "2.5rem", b: { xs: "4rem", md: "0" } }}
                  textWeight="500"
                  textColor="medium"
                  hoverTextColor="black"
                  transition
                  onClick={() => {
                    firebase.analytics().logEvent("view_blog")
                  }}
                >
                  Blog
                </Anchor>
              </div>

              <div style={{ paddingLeft: "0.8rem", paddingBottom: "0.4rem" }}>
                <Anchor
                  href="../help"
                  m={{ r: "2.5rem", b: { xs: "4rem", md: "0" } }}
                  textWeight="500"
                  textColor="medium"
                  hoverTextColor="black"
                  transition
                >
                  Hướng dẫn
                </Anchor>
              </div>

              <Link to="https://growth.ucall.vn/">
                <Button
                  bg="gray300"
                  hoverBg="gray400"
                  textColor="medium"
                  borderColor="gray500"
                  w={{ xs: "100%", sm: "8.5rem" }}
                  rounded="lg"
                  style={{
                    letterSpacing: "-0.5px",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    firebase.analytics().logEvent("header_login")
                  }}
                >
                  Dùng Thử
                </Button>
              </Link>
            </Div>
          </Container>
        </Div>
      </>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

const ProductHuntBanner = ({ show, close }) => (
  <>
    <Div
      d="flex"
      h={show ? { xs: "4.25rem", sm: "3.25rem" } : "0"}
      opacity={show ? "1" : "0"}
      overflow="hidden"
      flexWrap="wrap"
      justify="center"
      align="center"
      textColor="white"
      pos="fixed"
      bottom="0"
      left="0"
      right="0"
      zIndex="110"
      textAlign="center"
      p="0.75rem"
      style={{ background: "#2C39A0" }}
    >
      <Anchor
        href="https://growth.ucall.vn/"
        target="_blank"
        d="flex"
        flexWrap="wrap"
        justify="center"
        align="center"
        textColor="white"
        hoverTextColor="white"
        onClick={() => {
          firebase.analytics().logEvent("tap_bottom_banner")
        }}
      >
        👉 ƯU ĐÃI ĐẶC BIỆT: TẶNG GÓI DÙNG THỬ MIỄN PHÍ CHO 100 DOANH NGHIỆP ĐẦU
        TIÊN TRONG NĂM 2022!
      </Anchor>
      <Icon
        name="Cross"
        pos="absolute"
        right="1rem"
        top="50%"
        transform="translateY(-50%)"
        color="white"
        size="16px"
        onClick={close}
      />
    </Div>
  </>
)
