import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/common/header"
import { Text, Div, Tag, Row, Col, Image, Link, Container } from "atomize"
import Footer from "../components/homepage/Footer"

const Data = () => {
  return (
    <Layout>
      <Header layout="layout-2" />

      <div style={{ height: "36px" }} />
      <Div
        m="auto"
        p={{
          t: { xs: "6rem", md: "10rem" },
          l: { xs: "1.5rem", md: "2rem" },
          r: { xs: "1.5rem", md: "2rem" },
        }}
        flexDir="row"
        maxW={"800px"}
      >
        <Text
          textSize="display1"
          textWeight="700"
          fontFamily="secondary"
          color="info700"
          m={{ b: "1rem" }}
        >
          Chính sách bảo mật thông tin
        </Text>
        <p>
          <b>a) Mục đích và phạm vi thu thập thông tin khách hàng</b>
        </p>
        <br />
        <p>
          Việc thu thập thông tin khách hàng khi đăng ký là thành viên sử dụng
          trên uCall nhằm mục đích liên hệ khách hàng sử dụng dịch vụ khi có vấn
          đề phát sinh nhằm đảm bảo quyền lợi cho khách hàng.
        </p>
        <br />
        <p>
          Sau khi quá trình giao dịch thành công, thông tin về đơn hàng được lưu
          giữ nhưng uCall không lưu giữ thông tin về số tài khoản của khách
          hàng.
        </p>
        <br />
        <p>
          Các thành viên sẽ tự chịu trách nhiệm về bảo mật và lưu giữ đối với
          Tên đăng nhập, mật khẩu và địa chỉ email của mình. Nếu để tiết lộ các
          thông tin trên có thể bị kẻ xấu lợi dụng, uCall không chịu trách nhiệm
          trong trường hợp này. Trường hợp phát hiện tài khoản của mình bị đánh
          cắp vui lòng liên hệ với Ban quản trị để tìm được hướng giải quyết phù
          hợp.
        </p>
        <br />
        <p>
          <b>b) Phạm vi sử dụng thông tin khách hàng</b>
        </p>
        <br />
        <p>
          <ul>
            <li>Cung cấp các dịch vụ cho khách hàng;</li>
            <li>
              Liên lạc và giải quyết với khách hàng khi có vấn đề phát sinh;
            </li>
            <li>
              Không sử dụng thông tin cá nhân của khách hàng ngoài mục đích xác
              nhận đến các giao dịch của khách hàng tại uCall;
            </li>
          </ul>
        </p>
        <br />
        <p>
          Trong trường hợp có yêu cầu của cơ quan tư pháp như Viện Kiểm soát,
          Tòa án, cơ quan công an điều tra liên quan đến một hoạt động vi phạm
          pháp luật của Khách hàng thì chúng tôi có trách nhiệm hợp tác cung cấp
          thông tin cá nhân của khách hàng. Ngoài ra không ai có quyền xâm phạm
          thông tin cá nhân của khách hàng cũng như toàn bộ thành viên của
          uCall.
        </p>
        <br />
        <p>
          <b>c) Thời gian lưu trữ thông tin</b>
        </p>
        <br />
        <p>
          Dữ liệu cá nhân của Thành viên sẽ được lưu trữ cho đến khi có yêu cầu
          hủy bỏ hoặc tự thành viên đăng nhập và thực hiện hủy bỏ. Còn lại trong
          mọi trường hợp thông tin cá nhân thành viên sẽ được bảo mật trên máy
          chủ của uCall.
        </p>
        <br />
        <p>
          <b>d) Địa chỉ của đơn vị thu thập thông tin khách hàng</b>
        </p>
        <br />
        <p>
          <b>Công ty cổ phần công nghệ Ucall</b>
          <br />
          Địa chỉ: Số 221 Trần Quang Khải, Phường Tân Định, Quận 1, Thành phố Hồ
          Chí Minh, Việt Namm
          <br />
          Giấy chứng nhận đăng ký doanh nghiệp số 0109690915 Do Phòng ĐKKD - Sở
          KHĐT thành phố Hà Nội cấp lần đầu ngày 01/07/2021
          <br />
          Email: contact@ucall.asia - Đường dây nóng: 84327 335 877 335
        </p>
        <br />
        <p>
          <b>e) Quy trình chỉnh sửa dữ liệu cá nhân của khách hàng</b>
        </p>
        <br />
        <p>
          Thành viên hoàn toàn có thể đăng nhập vào tài khoản để chỉnh sửa dữ
          liệu cá nhân của mình bằng cách kích vào mục Thông tin tài khoản. Sau
          đó người dùng cập nhật lên thông tin cá nhân của mình. Sau khi hoàn
          tất kích vào Lưu lại để hoàn tất việc sửa đổi dữ liệu cá nhân.
        </p>
        <br />
        <p>
          <b>f) Cam kết bảo mật thông tin của khách hàng</b>
        </p>
        <br />
        <p>
          Thông tin cá nhân của khách hàng trên spaceshare.vn được cam kết bảo
          mật một cách tuyệt đối theo chính sách bảo mật thông tin của
          SpaceShare. Việc thu thập và sử dụng thông tin của khách hàng trên cơ
          sở sự đồng ý giao kết từ hai bên.
        </p>
        <br />
        <p>
          <b>uCall cam kết những nội dung sau:</b>
        </p>
        <br />
        <ul>
          <li>
            Không chuyển giao, tiết lộ thông tin khách hàng cho bất kỳ một bên
            thứ ba nào khác chỉ khi có sự đồng ý của khách hàng (trừ trường hợp
            có yêu cầu của cơ quan tư pháp như mục trên)
          </li>
          <li>
            Trong trường hợp hệ thống dữ liệu, máy chủ của uCall bị hacker tấn
            công dẫn đến việc bị mất, lộ thông tin cá nhân của khách hàng thì
            chúng tôi sẽ có trách nhiệm thông báo với cơ quan chức năng giải
            quyết và thông báo cho khách hàng được biết;
          </li>
          <li>
            Bảo mật tuyệt đối mọi giao dịch, thông tin hóa đơn thanh toán của
            khách hàng;
          </li>
          <li>
            Bản quản lý trang web yêu cầu khách hàng cung cấp đầy đủ mọi thông
            tin cá nhân khi đăng ký tài khoản sử dụng trên uCall bao gồm: tên,
            địa chỉ email, số điện thoại, địa chỉ và một số thông tin khác (nếu
            cần). Khách hàng phải chịu trách nhiệm về tính hợp pháp của những
            thông tin này. uCall sẽ không chịu trách nhiệm và không giải quyết
            khiếu nại có liên quan đến quyền lợi của khách hàng nếu những thông
            tin ban đầu khách hàng cung cấp cho uCall không chính xác.
          </li>
        </ul>
        <br />
        <div style={{ height: "64px" }} />
      </Div>
      <Footer />
    </Layout>
  )
}

export default Data
